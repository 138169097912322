<template>
	<div class="cont">
		<div class="top">
			<img class="top_img" :src="userInfo.avatarFile"></img>
			<div class="top_name">{{userInfo.nickname}}</div>
			<div class="top_text" v-if="!userInfo.vipInfo.fx.vipIds">你还没有开通VIP喔</div>
			<div class="top_text" v-else-if="currentUser.vipType == 2">你已开通 <span v-for="(itme ,index) in vipKList" 
					v-if="userInfo.vipInfo.fx.vipIds[item.id]">「{{item.content}}」</span>，可升级VIP
			</div>
			<div class="top_text" v-else-if="currentUser.vipType == 3">{{currentUser.vipOverDate}}到期</div>
		</div>
		<div class="vi">
			<div class="vi_top">请选择会员类型</div>
			<div class="vip">
				<div :class="'vip_li '+(userInfo.vipInfo.fx.vipIds[item.id]?'vip_li_no':'')+(current == index?'vip_li_yes':'')"
					:data-ons="userInfo.vipInfo.fx.vipIds[item.id]" v-for="(item, index) in vipKList"  catchtap="choice"
					:data-index="index">
					<div class="vip_li_top">{{item.title}}</div>
					<div class="vip_li_h4">{{item.content}}</div>
					<div class="vip_li_price"><span>¥</span>{{item.groupPrice}}
						<div class="pin">拼团</div>
					</div>
					<div class="vip_li_text">¥{{item.current}}</div>
					<img class="vip_li_img" src="./img/yd_xsnd_d.png"></img>
					<img class="vip_li_img_bq_ygm" src="./img/bq_ygm.png"></img>
				</div>
			</div>
			<div class="vip_vi" v-if="!userInfo.vipInfo.fx.vipIds[vipKList[current].id]">
				<div class="vip_btn vip_btn01" catchtap="buy">直接购买 ¥{{vipKList[current].current}}</div>

				<div class="vip_btn vip_btn02" catchtap="goPages"
					:data-url="'/pages/html/index?url='+vipKList[current].yzUrl">低价拼团 ¥{{vipKList[current].groupPrice}}
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 22px;background: #F7F7F8;"></div>
		<button v-if="!currentUser.mobile" class="tip" open-type="getPhoneNumber" bindgetphonenumber="getPhoneNumber">
		</button>
		<div class="imgs" v-html="vipKList[current].detail">
			<!-- <rich-text class="rich" nodes="{{vipKList[current].detail}}"></rich-text> -->
		</div>
		<van-loading class="loading" size="24px" v-if="Loadings" vertical>加载中...</van-loading>
	</div>
</template>

<script>
		var api = ""
		if (location.host.indexOf("dev") != -1) {
			api = "https://dev.kyyy.xiaobaikaoyan.com/api";
		} else {
			api = "https://kyyy.ikaoyaner.com/api";
		}
		api = location.origin
	import {
		Icon,
		Loading
	} from 'vant';
	export default {
		components: {
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		
		data() {
			return {
				Loadings: false,
				current: 0,
				vipKList: [{id:0}],
				userInfo: {
					vipInfo: {
						fx: {
							vipIds: [{
								id:0
							}]
						}
					}
				},
				currentUser: {
					vipType: 1,
					vipOverDate: "",
					avatarFile: "",
				}
			}
		},
		created() {
			let code = this.query1("code");
			let storage = window.sessionStorage;
			let appid = storage.getItem('appid')
			let Openids = storage.getItem('Openids') ? JSON.parse(storage.getItem('Openids')) : {};
			this.Openid = Openids[appid]
			if (Openids[appid]) {
				this.getfindByMpOpenId(Openids[appid])
			} else if (code) {
				this.getOpenId(code)
			} else {}
			this.agreementVIP()
			this.countAllLikeTogether();
		},
		methods: {
			
			getOpenId(code) {
				let storage = window.sessionStorage;
				let appid = storage.getItem('appid')
				axios.get(api + '/mp/getOpenId/' + appid + '/' + code, {})
					.then(res => {
						if (res.data && res.data.data) {
							var Openid = res.data.data
							this.Openid = Openid;
			
							var storage = window.sessionStorage;
							let Openids = storage.getItem('Openids') || {}
							Openids[appid] = Openid
							Openids = JSON.stringify(Openids)
							storage.setItem('Openids', Openids);
							this.getfindByMpOpenId(Openid)
						}
					}, err => {
						console.log(err)
					})
					.catch((error) => {
						console.log(error)
					})
			},
			
			query1(name) {
				const search = location.search.substr(1); // 得到类似于 a=10&b=20&c=30
				const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i'); // i 忽略大小写
				const res = search.match(
					reg); // 返回格式 0: "a=10&" 1: "" 2: "10" 3: "&" groups: undefined index: 0 input: "a=10&b=20&c=30"
				if (res === null) {
					return null
				}
				return res[2];
			},
		}
	}
</script>

<style scoped="scoped">
	.tip {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: transparent;
	}

	.top {
		height: 280px;
		background-color: #282730;
		text-align: center;
	}

	.top_img {
		width: 88px;
		height: 88px;
		border: 4px solid #fff;
		border-radius: 50%;
		margin-top: 30px;
	}

	.top_name {
		font-size: 30px;
		font-weight: bold;
		color: #fff;
		margin-top: 14px;
	}

	.top_text {
		font-size: 24px;
		color: #B1ABAB;
		margin-top: 10px;
	}

	.vi {
		width: 750px;
		height: 640px;
		background: #FFFFFF;
		border-radius: 30px 30px 0px 0px;
		margin: -30px auto;
		overflow: hidden;
	}

	.vi_top {
		font-size: 32px;
		color: #4D4D54;
		margin: 48px 0;
		text-align: center;
	}

	.vip {
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.vip_li {
		width: 218px;
		height: 270px;
		background: #FFFFFF;
		border-radius: 19px 19px 26px 19px;
		border: 4px solid #E5E4E4;
		position: relative;
		text-align: center;
		box-sizing: border-box;
	}

	.vip_li_top {
		width: 123px;
		height: 40px;
		background: linear-gradient(215deg, #F8D496 0%, #FFEBC0 100%);
		border-radius: 14px;
		font-size: 24px;
		color: #704B1D;
		line-height: 40px;
		text-align: center;
		position: absolute;
		left: 48px;
		top: -22px
	}

	.vip_li_h4 {
		font-size: 28px;
		color: #636363;
		font-weight: bold;
		margin-top: 60px;
		line-height: 40px;
	}

	.vip_li_price {
		font-size: 56px;
		font-weight: bold;
		color: #000000;
		margin-top: 26px;
		position: relative;
	}

	.vip_li_price text {
		font-size: 27px;
	}

	.vip_li_text {
		text-decoration: line-through;
		font-size: 28px;
		color: #AAA19B;
		margin-top: 6px;
	}

	.pin {
		width: 46px;
		height: 24px;
		line-height: 20px;
		background: #FFD4C7;
		border-radius: 9px 9px 9px 0;
		font-size: 12px;
		color: #EE4C01;
		position: absolute;
		right: 16px;
		top: -6px;
	}

	.vip_li_img {
		width: 40px;
		height: 40px;
		position: absolute;
		right: 0;
		bottom: 0;
		display: none;
	}

	.vip_li_yes {
		border: 4px solid #FF5F00;
	}

	.vip_li_yes .vip_li_top {
		background: linear-gradient(299deg, #FE8152 0%, #FF4F0C 100%);
		color: #fff;
	}

	.vip_li_yes .vip_li_h4 {
		color: #712E08;
	}

	.vip_li_yes .vip_li_price {
		color: #FF5F00;
	}

	.vip_li_yes .vip_li_img {
		display: block;
	}

	.vip_vi {
		margin-top: 70px;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.vip_btn {
		width: 334px;
		height: 98px;
		border-radius: 49px;
		text-align: center;
		line-height: 98px;
	}

	.vip_btn01 {
		background-color: #F9D798;
		font-size: 32px;
		color: #834737;
	}

	.vip_btn02 {
		background-color: #413B3B;
		font-size: 32px;
		color: #ffffff;
	}

	.rich .imgS {
		width: 100%;
	}

	.vip_li_no {
		filter: grayscale(100%)
	}

	.vip_li_img_bq_ygm {
		width: 84px;
		height: 74px;
		position: absolute;
		right: 0;
		bottom: 0;
		display: none;
	}

	.vip_li_no .vip_li_img_bq_ygm {
		display: block;
	}

	.vip_li_no .vip_li_img {
		display: none;
	}
</style>
